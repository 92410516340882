import React from 'react';
import './styles.scss';
import { Fade } from 'react-reveal';

class RealStory extends React.Component {
    render() {
        return (
            <div className='virtualRealStoryWrap'>
                <div className='virtualContainer'>
                    <div className='virtualRealStoryInner'>
                        <div className='virtualRealStoryLeft'>
                            <div className='virtualRealStoryHeader'>
                                <Fade top cascade>
                                    <div className='virtualRealStoryHeaderLeft'>
                                        <img
                                            src={
                                                'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/purple-open-qoutes.svg'
                                            }
                                            data-src={
                                                'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/purple-open-qoutes.svg'
                                            }
                                            alt={'quote open'}
                                        />
                                    </div>
                                    <div className='virtualRealStoryHeaderRight'>
                                        <h3>Real Stories from Real Customers</h3>
                                        <p>Get inspired by these stories.</p>
                                    </div>
                                </Fade>
                            </div>

                            <Fade left>
                                <div className='virtualRealStoryCard'>
                                    <div className='RealStorylogo'>
                                        <img
                                            src={
                                                'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/vedantu-transparent-bg.png?blur=30'
                                            }
                                            data-src={
                                                'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/vedantu-transparent-bg.png'
                                            }
                                            alt={'vedantu'}
                                        />
                                    </div>
                                    <div className='virtualRealStoryCardInner'>
                                        <div className='virtualRealStoryCardInnerLeft'>
                                            <img
                                                src={
                                                    'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/purple-open-qoutes.svg'
                                                }
                                                data-src={
                                                    'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/purple-open-qoutes.svg'
                                                }
                                                alt={'quote open'}
                                            />
                                        </div>
                                        <div className='virtualRealStoryCardInnerRight'>
                                            <p>
                                                An Indian Interactive Online tutoring platform added 52,000 minutes of
                                                Remote Employee Engagement with Trivia.
                                            </p>
                                            <a
                                                href='https://www.springworks.in/blog/trivia-at-vedantu-a-case-study/'
                                                target='_blank'
                                                rel='noreferrer noopener'
                                            >
                                                Read now
                                                <img
                                                    src={
                                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/black-right-arrow.svg'
                                                    }
                                                    data-src={
                                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/black-right-arrow.svg'
                                                    }
                                                    alt={'open'}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>

                        <div className='virtualRealStoryRight'>
                            <Fade right>
                                <div className='virtualRealStoryCard'>
                                    <div className='RealStorylogo'>
                                        <img
                                            src={
                                                'https://sw-assets.gumlet.io/trivia/customers/science-transparent-bg.png?blur=30'
                                            }
                                            data-src={
                                                'https://sw-assets.gumlet.io/trivia/customers/science-transparent-bg.png'
                                            }
                                            alt={'science'}
                                        />
                                    </div>
                                    <div className='virtualRealStoryCardInner'>
                                        <div className='virtualRealStoryCardInnerLeft'>
                                            <img
                                                src={
                                                    'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/purple-open-qoutes.svg'
                                                }
                                                data-src={
                                                    'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/purple-open-qoutes.svg'
                                                }
                                                alt={'quote open'}
                                            />
                                        </div>
                                        <div className='virtualRealStoryCardInnerRight'>
                                            <p>
                                                How Trivia helped a venture studio worth more than $1.3B to bond better,
                                                together as a remote team?
                                            </p>
                                            <a
                                                href='https://www.springworks.in/blog/science-inc-case-study/'
                                                target='_blank'
                                                rel='noreferrer noopener'
                                            >
                                                Read now
                                                <img
                                                    src={
                                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/black-right-arrow.svg'
                                                    }
                                                    data-src={
                                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/black-right-arrow.svg'
                                                    }
                                                    alt={'open'}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='virtualRealStoryCard'>
                                    <div className='RealStorylogo'>
                                        <img
                                            src={
                                                'https://sw-assets.gumlet.io/trivia/customers/skillenza-transparent-bg.png?blur=30'
                                            }
                                            data-src={
                                                'https://sw-assets.gumlet.io/trivia/customers/skillenza-transparent-bg.png'
                                            }
                                            alt={'skillenza'}
                                        />
                                    </div>
                                    <div className='virtualRealStoryCardInner'>
                                        <div className='virtualRealStoryCardInnerLeft'>
                                            <img
                                                src={
                                                    'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/purple-open-qoutes.svg'
                                                }
                                                data-src={
                                                    'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/purple-open-qoutes.svg'
                                                }
                                                alt={'quote open'}
                                            />
                                        </div>
                                        <div className='virtualRealStoryCardInnerRight'>
                                            <p>
                                                How a data-driven skill acceleration platform reinvented virtual
                                                icebreakers with Trivia?
                                            </p>
                                            <a
                                                href='https://www.springworks.in/blog/trivia-at-skillenza/'
                                                target='_blank'
                                                rel='noreferrer noopener'
                                            >
                                                Read now
                                                <img
                                                    src={
                                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/black-right-arrow.svg'
                                                    }
                                                    data-src={
                                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/black-right-arrow.svg'
                                                    }
                                                    alt={'open'}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RealStory;
