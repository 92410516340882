import React from 'react';
import './styles.scss';
import Fade from 'react-reveal/Fade';

class RemoteTeam extends React.Component {
    render() {
        return (
            <div className='virtualRemoteWrap'>
                <div className='virtualContainer'>
                    <div className='remoteHeader'>
                        <Fade top>
                            <h2>What can Water Cooler do for your remote team?</h2>
                        </Fade>
                    </div>
                    <Fade left cascade>
                        <div className='remoteCardWrap'>
                            <div className='remoteCard'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/foster.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/foster.svg'}
                                    alt={'foster connections'}
                                />
                                <h4>Foster Connections</h4>
                                <p>
                                    Introduce peers virtually, encourage impromptu meet-ups and social collisions that
                                    are centered around non-work related topics.
                                </p>
                            </div>
                            <div className='remoteCard'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/spark.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/spark.svg'}
                                    alt={'Build culture'}
                                />
                                <h4>Spark Conversations</h4>
                                <p>
                                    Inspire thoughtful discussions and friendly debates for your peers to know each
                                    other, better.
                                </p>
                            </div>
                            <div className='remoteCard'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/build.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/build.svg'}
                                    alt={'Build culture'}
                                />
                                <h4>Build a Lasting Culture</h4>
                                <p>
                                    Build trust and strengthen important internal networks even while working
                                    asynchronously across time zones.{' '}
                                </p>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
}

export default RemoteTeam;
