import React, { useState } from 'react';
import './styles.scss';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import downArrow from '../images/down-arrow.svg';
import upArrow from '../images/arrowUp.png';

const Example = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ display: 'flex', alignItems: 'center' }}>
            <DropdownToggle>
                Games
                <img className='mob-arrow-nav' src={downArrow} alt={'down'} />
            </DropdownToggle>
            <div className='DropdownMenuWrap'>
                <DropdownMenu>
                    <DropdownItem href='/trivia/games/custom-quiz/' onClick={toggle}>
                        Custom Quiz
                    </DropdownItem>
                    <DropdownItem href='/trivia/pictionary-online/' onClick={toggle}>
                        Pictionary
                    </DropdownItem>
                    <DropdownItem href='/trivia/games/' onClick={toggle}>
                        All Games
                    </DropdownItem>
                    <DropdownItem href='/trivia/internal-branding/' onClick={toggle}>
                        Internal Branding Quizzes
                    </DropdownItem>
                    <img className='arrow-up' src={upArrow} alt={'up'} />
                </DropdownMenu>
            </div>
        </Dropdown>
    );
};

export default Example;
