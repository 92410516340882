import React from 'react';
import './styles.scss';
import { Bounce, Fade } from 'react-reveal';

class Works extends React.Component {
    render() {
        return (
            <div className='virtualWorksWrap'>
                <div className='virtualContainer'>
                    <div className='virtualWorksHeader'>
                        <Fade top>
                            <h2>How does it work?</h2>
                        </Fade>
                    </div>

                    <div className='virtualWorksimagery'>
                        <Bounce left>
                            <img
                                src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/how-it-works-group.svg'}
                                data-src={
                                    'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/how-it-works-group.svg'
                                }
                                alt={'how does it work?'}
                            />
                        </Bounce>
                    </div>

                    <div className='virtualWorksSecWrap'>
                        <Fade left cascade>
                            <div className='virtualWorksSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/channel.png?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/channel.png'}
                                    alt={'choose channel'}
                                />
                                <h5>Choose a channel</h5>
                                <p>
                                    Select a channel where you want to initiate conversations or create a dedicated
                                    Water Cooler channel to keep connections more channelized.
                                </p>
                            </div>
                            <div className='virtualWorksSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/post.png?blur=30'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/post.png'}
                                    alt={'Post questions'}
                                />
                                <h5>Post questions</h5>
                                <p>
                                    Simply choose the category and the Trivia bot will automatically post a question in
                                    the channel of your choice.
                                </p>
                            </div>
                            <div className='virtualWorksSec'>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/socialize.png?blur=30'
                                    }
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/socialize.png'}
                                    alt={'Socialize'}
                                />
                                <h5>Socialize serendipitously</h5>
                                <p>
                                    Want to connect your Sales Executive in LA with an Engineering buddy in London? We
                                    make socializing effortless by helping individuals connect on a personal level.
                                </p>
                            </div>
                        </Fade>
                    </div>

                    <div className='virtualProductivityWrap'>
                        <div className='virtualProductivityLeft'>
                            <Bounce right>
                                <img
                                    src={
                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/productivity.svg?blur=30'
                                    }
                                    data-src={
                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/productivity.svg'
                                    }
                                    alt={'productivity'}
                                />
                            </Bounce>
                        </div>

                        <div className='virtualProductivityRight'>
                            <Fade right>
                                <h3>If you think Water Cooler talks hinders productivity… </h3>
                                <p>
                                    Casual Water Cooler chatter doesn't mean people are goofing off. Friendly chat can
                                    de-stress and recharge individuals quickly. When they return, they'll have a fresh
                                    mindset and get more done. Sometimes these talks have the potential to be the cradle
                                    for creativity making a positive impact on work!
                                </p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Works;
