import React from 'react';
import './styles.scss';

class Achievement extends React.Component {
    render() {
        return (
            <div className='virtualAchievementWrap'>
                <div className='virtualAchievement virtualAchievementDesk'>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/section-end.png?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/section-end.png'}
                        alt={''}
                    />
                </div>
                <div className='virtualAchievement virtualAchievementMob'>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/section-end-mob.png?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/section-end-mob.png'}
                        alt={''}
                    />
                </div>

                <div className='virtualAchievementContent virtualAchievementContentDesk'>
                    <div className='virtualContainer'>
                        <span>Our achievement in the journey depicted in numbers</span>
                        <div className='achievementContent'>
                            <div className='achievementSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/happy-clients.svg'}
                                    data-src={
                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/happy-clients.svg'
                                    }
                                    alt={'happy clients'}
                                />
                                <h3>4500+</h3>
                                <p>No of organizations</p>
                            </div>
                            <div className='achievementSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/projects.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/projects.svg'}
                                    alt={'players'}
                                />
                                <h3>130,000+</h3>
                                <p>No of Players</p>
                            </div>
                            <div className='achievementSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/dedicated.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/dedicated.svg'}
                                    alt={'games played'}
                                />
                                <h3>200,000+</h3>
                                <p>Games Played</p>
                            </div>
                            <div className='achievementSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/awards.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/awards.svg'}
                                    alt={'engagements'}
                                />
                                <h3>80,000+</h3>
                                <p>Reactions and Comments</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='virtualAchievementContent virtualAchievementContentMob'>
                    <div className='virtualContainer'>
                        <span>Our achievement in the journey depicted in numbers</span>

                        <div className='achievementContent'>
                            <div className='achievementSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/happy-clients.svg'}
                                    data-src={
                                        'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/happy-clients.svg'
                                    }
                                    alt={'happy clients'}
                                />
                                <h3>4500+</h3>
                                <p>No of organizations</p>
                            </div>
                            <div className='achievementSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/projects.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/projects.svg'}
                                    alt={'players'}
                                />
                                <h3>130,000+</h3>
                                <p>No of Players</p>
                            </div>
                        </div>
                        <div className='achievementContent'>
                            <div className='achievementSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/dedicated.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/dedicated.svg'}
                                    alt={'games played'}
                                />
                                <h3>200,000+</h3>
                                <p>Games Played</p>
                            </div>
                            <div className='achievementSec'>
                                <img
                                    src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/awards.svg'}
                                    data-src={'https://sw-assets.gumlet.io/trivia/virtual-water-cooler/awards.svg'}
                                    alt={'engagements'}
                                />
                                <h3>80,000+</h3>
                                <p>Reactions and Comments</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Achievement;
