import React, { useState } from 'react';
import './styles.scss';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import downArrow from '../images/down-arrow.svg';
import upArrow from '../images/arrowUp.png';

const Example = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ display: 'flex', alignItems: 'center' }}>
            <DropdownToggle>
                Resources
                <img className='mob-arrow-nav' src={downArrow} alt={''} />
            </DropdownToggle>
            <div className='DropdownMenuWrap'>
                <DropdownMenu>
                    <DropdownItem
                        href='https://www.springworks.in/blog/category/trivia/'
                        target='_blank'
                        rel='noreferrer noopener'
                        onClick={toggle}
                    >
                        Blog
                    </DropdownItem>
                    <DropdownItem
                        href='https://springworks.tawk.help/category/trivia-knowledge-base'
                        target='_blank'
                        rel='noreferrer noopener'
                        onClick={toggle}
                    >
                        User Guide
                    </DropdownItem>
                    <DropdownItem
                        href='https://springworks.tawk.help/category/trivia-knowledge-base/support-videos'
                        target='_blank'
                        rel='noreferrer noopener'
                        onClick={toggle}
                    >
                        Videos
                    </DropdownItem>
                    <DropdownItem href='/trivia/faq/' onClick={toggle}>
                        FAQ
                    </DropdownItem>
                    <img className='arrow-up' src={upArrow} alt={''} />
                </DropdownMenu>
            </div>
        </Dropdown>
    );
};

export default Example;
