import React from 'react';
import './styles.scss';
import { Bounce, Fade } from 'react-reveal';

const images = ['consenso.png', 'ea1-logo.svg', 'science.png', 'quora.png', 'shopify.png', 'skillenza.png'];

const Customer = ({ image }) => (
    <span>
        <img
            src={`https://sw-assets.gumlet.io/trivia/customers/${image}?blur=30`}
            data-src={`https://sw-assets.gumlet.io/trivia/customers/${image}`}
            alt={image.split('-')[0]}
        />
    </span>
);

class TopBrands extends React.Component {
    render() {
        return (
            <div className='virtualTopBrandsWrap'>
                <div className='virtualContainer'>
                    <div className='virtualTopBrandsHeader'>
                        <Fade top cascade>
                            <h2>4,500+</h2>
                            <p>Top Brands Trust Trivia for effective Team Building </p>
                        </Fade>
                    </div>
                    <div className='virtualTopBrands'>
                        <Bounce left cascade slow>
                            {images.map((image) => (
                                <Customer image={image} key={image} />
                            ))}
                        </Bounce>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBrands;
