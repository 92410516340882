import React from 'react';
import './styles.scss';
import Fade from 'react-reveal/Fade';
import { getUtmParams } from '../../../../util/getUtmFromUrl';

const slackAddUrl = process.env.GATSBY_TRIVIA_SLACK_ADD_URL;
const teamsAddUrl = process.env.GATSBY_TRIVIA_MS_TEAMS_URL;

const images = [
    'science-white.png',
    'sugoilabs-white.png',
    'quora-white.png',
    'ibm.png',
    'ea-white.png',
    'shopify-white.png',
    'consenso.png',
    'skillenza-white.png',
];

const Customer = ({ image }) => (
    <span>
        <img
            src={`https://sw-assets.gumlet.io/trivia/customers/${image}?blur=30`}
            data-src={`https://sw-assets.gumlet.io/trivia/customers/${image}`}
            alt={image.split('-')[0]}
        />
    </span>
);

class Header extends React.Component {
    state = {
        utm: '',
    };

    componentDidMount() {
        const utm = getUtmParams();
        this.setState({ utm });
    }

    render() {
        const { utm } = this.state;
        return (
            <>
                <div className='virtualHeaderWrap'>
                    <Fade top cascade delay='500'>
                        <div className='virtualHeaderLeft'>
                            <h1>Virtual Water Cooler for Remote Teams</h1>
                            <p>
                                Open-up lines of communication and recreate the office watercooler through virtual
                                coffees, peer introductions, and connection programs.
                            </p>

                            <div className='virtualHeaderCTA'>
                                <button
                                    onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                                    id='TRIVIA-SLACK-INSTALL'
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                        alt={'slack'}
                                    />
                                    Add Trivia to Slack
                                </button>
                                <button
                                    onClick={() => window.open(encodeURI(`${teamsAddUrl}${utm}`), '_blank')}
                                    id='TRIVIA-TEAMS-INSTALL'
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                        alt={'microsoft teams'}
                                    />
                                    Add Trivia to Teams
                                </button>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className='virtualHeaderLogo virtualHeaderLogoDesk'>
                    <div className='virtualHeaderLogoInner'>
                        {images.map((image) => (
                            <Customer image={image} key={image} />
                        ))}
                    </div>
                </div>
                <div className='virtualHeaderLogo virtualHeaderLogoMob'>
                    <div className='virtualHeaderLogoInner'>
                        {images.map((image) => (
                            <Customer image={image} key={image} />
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default Header;
