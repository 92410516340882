import React from 'react';
import './styles.scss';
import StartModal from '../StartModal';
import PlatformDropdown from './PlatformDropdown';
import Resources from './Resources';
import Games from './Games';
import { Link } from 'gatsby';
import { getUtmParams } from '../../../util/getUtmFromUrl';

class NavbarOld extends React.Component {
    state = {
        displayLink: false,
        addTo: false,
        onCta: false,
        utm: '',
        show: false,
        gotoPage: 'Home',
        login: false,
    };

    componentDidMount() {
        const utm = getUtmParams();
        let gotoPage = 'Home';
        if (window.location.pathname === '/trivia/') {
            gotoPage = 'FAQ';
        }
        this.setState({ utm, gotoPage });
    }

    render() {
        return (
            <div className='headerCtr' sticky={'top'}>
                <div className='logo'>
                    <Link to={'/trivia/'}>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/logos/trivia-logo.svg'}
                            data-src={'https://sw-assets.gumlet.io/trivia/logos/trivia-logo.svg'}
                            alt={'Trivia logo'}
                        />
                    </Link>
                </div>
                <div className='linkCtr'>
                    <div className='pageMenu'>
                        <PlatformDropdown name='Platforms' />
                        <Games name='Games' />
                        <Link to={'/trivia/pricing/'}>Pricing</Link>
                        <Resources name='Resources' />
                        <Link to={'/trivia/customers/'}>Success Stories</Link>
                    </div>

                    <div
                        role={'link'}
                        className='link'
                        tabIndex={'0'}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) this.setState({ show: true, login: true });
                        }}
                        onClick={() => this.setState({ show: true, login: true })}
                    >
                        {'Login'}
                    </div>
                    <div
                        id='ADD-TRIVIA-NOW-HEADER'
                        role={'link'}
                        className={this.state.addTo ? 'headerAddTriviaActive' : 'headerAddTrivia'}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) this.setState({ show: true, login: true });
                        }}
                        tabIndex={'0'}
                        onClick={() => this.setState({ show: true, login: false })}
                    >
                        Add <b>Trivia</b>
                    </div>
                    <StartModal
                        login={this.state.login}
                        show={this.state.show}
                        hide={() => this.setState({ show: false })}
                        slackAddUrl={process.env.GATSBY_TRIVIA_SLACK_ADD_URL}
                        teamsAddUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_URL}
                        slackLoginUrl={process.env.GATSBY_TRIVIA_SLACK_LOGIN_URL}
                        teamsLoginUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_LOGIN_URL}
                    />
                </div>
                <div
                    className='menuListIcon'
                    role={'button'}
                    tabIndex={'0'}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) this.setState({ displayLink: true });
                    }}
                    onClick={() => this.setState({ displayLink: true })}
                >
                    <img
                        data-src={'https://sw-assets.gumlet.io/trivia/misc/menuListIcon.svg'}
                        src={'https://sw-assets.gumlet.io/trivia/misc/menuListIcon.svg'}
                        alt={'menu'}
                    />
                </div>
                {this.state.displayLink ? (
                    <div className='mobileLinkCtr'>
                        <div className='innerCtrHeader'>
                            <div
                                role={'button'}
                                tabIndex={'0'}
                                className='crossMenuIcon'
                                onClick={() => this.setState({ displayLink: false })}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) this.setState({ displayLink: false });
                                }}
                            >
                                <img
                                    data-src={'https://sw-assets.gumlet.io/trivia/misc/crossMenuIcon.svg'}
                                    src={'https://sw-assets.gumlet.io/trivia/misc/crossMenuIcon.svg'}
                                    alt={'close'}
                                />
                            </div>

                            <div className='pageMenu'>
                                <PlatformDropdown name='Platforms'>
                                    <Link to={'/trivia/slack/'}>
                                        <img
                                            src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                            data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                            alt={'slack'}
                                        />
                                        Slack
                                    </Link>
                                    <Link to={'/trivia/microsoft-teams/'}>
                                        <img
                                            src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                            data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                            alt={'microsoft teams'}
                                        />
                                        MS Teams
                                    </Link>
                                </PlatformDropdown>
                                <PlatformDropdown name='Games'>
                                    <Link to={'/trivia/games/custom-quiz/'}>Custom Quiz</Link>
                                    <Link to={'/trivia/games/'}>All Games</Link>
                                </PlatformDropdown>
                                <Link to={'/trivia/pricing/'}>Pricing</Link>
                                <PlatformDropdown name='Resources'>
                                    <a
                                        href='https://www.springworks.in/blog/category/trivia/'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Blog
                                    </a>
                                    <a
                                        href='https://springworks.tawk.help/category/trivia-knowledge-base'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        User Guide
                                    </a>
                                    <a
                                        href='https://springworks.tawk.help/category/trivia-knowledge-base/support-videos'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Videos
                                    </a>
                                    <Link to={'/trivia/faq/'}>FAQ</Link>
                                </PlatformDropdown>
                                <Link to={'/trivia/customers/'}>Success Stories</Link>
                            </div>

                            <div className='HamberLogins'>
                                <button onClick={() => this.setState({ show: true, login: true })}>Login</button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default NavbarOld;
