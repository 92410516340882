import React from 'react';
import Navbar from '../../../components/trivia/NavbarOld';
import Header from '../../../components/trivia/virtual-water-cooler/Header';
import Footer from '../../../components/trivia/Footer';
import './styles.scss';
import RemoteTeam from '../../../components/trivia/virtual-water-cooler/RemoteTeam';
import Works from '../../../components/trivia/virtual-water-cooler/Works';
import TopBrands from '../../../components/trivia/virtual-water-cooler/TopBrands';
import RealStory from '../../../components/trivia/virtual-water-cooler/RealStory';
import TriviaUsers from '../../../components/trivia/TriviaUsers';
import Testimonials from '../../../components/trivia/TestimonialsOld';
import { Helmet } from 'react-helmet';
import Achievement from '../../../components/trivia/virtual-water-cooler/Achievement';

class VirtualWaterCooler extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Virtual Water Cooler | Trivia</title>
                    <meta
                        name='description'
                        content='Spark fun & engaging conversations in Slack & MS Teams. Recreate office banter with thoughtful discussions & friendly debates with a virtual water cooler by Trivia.'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='Virtual Water Cooler | Trivia' />
                    <meta
                        property='og:description'
                        content='Spark fun & engaging conversations in Slack & MS Teams. Recreate office banter with thoughtful discussions & friendly debates with a virtual water cooler by Trivia.'
                    />
                    <meta property='og:url' content='https://www.springworks.in/trivia/virtual-water-cooler/' />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:image'
                        content='https://assets-springworks.s3.amazonaws.com/VirtualWaterCooler.jpg'
                    />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/VirtualWaterCooler.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta property='twitter:title' content='Virtual Water Cooler | Trivia' />
                    <meta
                        property='twitter:description'
                        content='Spark fun & engaging conversations in Slack & MS Teams. Recreate office banter with thoughtful discussions & friendly debates with a virtual water cooler by Trivia.'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://assets-springworks.s3.amazonaws.com/VirtualWaterCooler.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/trivia/virtual-water-cooler/' />
                </Helmet>
                <div className='virtualWaterCoolerWrap'>
                    <Navbar />
                    <Header />
                    <RemoteTeam />
                    <Achievement />
                    <Works />
                    <TopBrands />
                    <Testimonials />
                    <RealStory />
                    <TriviaUsers />
                    <Footer />
                </div>
            </>
        );
    }
}

export default VirtualWaterCooler;
