import React from 'react';
import './styles.scss';
import Carousel from 'react-bootstrap/Carousel';
import { TESTIMONIALS } from './_data';

class TestimonialsOld extends React.Component {
    render() {
        return (
            <div className='testimonialsWrapper'>
                <Carousel>
                    {TESTIMONIALS.map((testimonial, index) => {
                        return (
                            <Carousel.Item key={index}>
                                <div className='tesimonal'>
                                    <div className='quotes'>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/misc/quote-icon-left.svg'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/misc/quote-icon-left.svg'}
                                            alt=''
                                        />
                                    </div>
                                    <div className='tesimonalMessage'>
                                        <h2>{testimonial.review}</h2>
                                    </div>
                                    <div className='quotes quotesRight'>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/misc/quote-icon-right.svg'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/misc/quote-icon-right.svg'}
                                            alt=''
                                        />
                                    </div>
                                </div>
                                <div className='tesimonalUser'>
                                    <div className='tesimonalUserProfile'>
                                        <div className='profileArrow'>
                                            <img
                                                src={'https://sw-assets.gumlet.io/trivia/misc/white-diamond.svg'}
                                                data-src={'https://sw-assets.gumlet.io/trivia/misc/white-diamond.svg'}
                                                alt=''
                                            />
                                        </div>
                                        {testimonial.picture}
                                    </div>
                                    <div className='tesimonalUserProfileDesc'>
                                        {testimonial.company}
                                        <h3>{testimonial.name}</h3>
                                        <p>{testimonial.position}</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>
        );
    }
}

export default TestimonialsOld;
